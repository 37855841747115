import React, { useEffect } from 'react'
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";

const TagsPage = props => {
  const {
    data: {
      allMarkdownRemark: { group }
    }
  } = props;

  useEffect(() => {
        document.getElementById('lang-switch').href = 'https://e-multicontent.com/tags/'
   });

  return (
    <Layout>
    <nav className="breadcrumbs-nav section">
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Tagi</b></span>
    </nav>
      <Helmet title={`Tagi | e-multicontent`}>
      <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: "6rem" }}
            >
            <h1 className="title" style={{marginTop:'50px'}}>Tagi</h1>
            <br />
              <ul className="taglist">
                {group.map(tag => (
                  <li key={tag.fieldValue}>
                    <Link
                      className="btn"
                      to={`/tagi/${kebabCase(tag.fieldValue)}/`}
                    >
                      {tag.fieldValue} ({tag.totalCount})
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
